import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../css/gallery.css';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imgUrl) => {
    setIsOpen(true);
    setSelectedImage(imgUrl);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    fetch('https://wedd-7034.onrender.com')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.images)) {
          setImages(data.images);
        } else {
          throw new Error('Invalid data format or missing images');
        }
      })
      .catch((error) => {
        console.error('Error fetching or processing data:', error);
        // Handle the error or set state to show an error message
      });
  }, []);
  

  return (
    <div>
      <h2 className="text-center my-3">All Images</h2>
      <br></br>
      <div className="row">
        {images.map((img) => (
          <div key={img._id} className="col-md-4 mb-3">
            <div className="card">
              <img src={img.imgUrl} className="card-img-top" alt={img.imgUrl} onClick={() => openModal(img.imgUrl)} />
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal">
  
  {selectedImage && <div className="modal-content"><img src={selectedImage} alt={selectedImage} /></div>}
  <button onClick={closeModal}>Close</button>
</Modal>
    </div>
  );
};

export default ImageGallery;
