import React from 'react';
import '../css/header.css'; // Update the path to your CSS file if necessary

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <img
            src="https://th.bing.com/th/id/OIG.RsIo2pau88UQJvwpaluD?w=1024&h=1024&rs=1&pid=ImgDetMain"
            alt="Description of the image"
            className="header-image"
          />
        </div>
        <div className="header-center">
          <h1>Ram Vivah Bhawan</h1>
          <h2>Maa Gauri Vivah Bhawan</h2>
        </div>
        <div className="header-right">
          <p>Phone: <a href="tel:800-202-8277">800-202-8277</a></p>
          <p>Email: <a href="mailto:shreeramvivahbhawan@gmail.com">Ram Vivah Bhawan</a></p>
        </div>
      </div>
    </header>
  );
};

export default Header;
