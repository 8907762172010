// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css'; // Import your CSS file

const Navbar = () => {
  const navItems = [
    { title: 'Home', link: '/' },
    { title: 'Events', link: '/events' },
    { title: 'Gallery', link: '/gallery' },
    { title: 'Venue', link: '/venue' },
    { title: 'Booking', link: '/booking' },
    { title: 'Contact', link: '/contact' },
  ];

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="navbar">
      <div className="menu-large">
        <ul className="nav-links">
          {navItems.map((item, index) => (
            <li key={index}>
              <Link to={item.link}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="menu-small">
        <div className="dropdown">
          <button className="dropbtn" onClick={toggleMenu}>
          &#9776;
          </button>
          <div className={`dropdown-content ${showMenu ? 'show' : ''}`}>
            {navItems.map((item, index) => (
              <Link key={index} to={item.link} onClick={toggleMenu}>
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
