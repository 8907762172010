import React from "react";
import "../css/event.css"; // Import your CSS file for styling

const EventDetailPage = () => {
  return (
    <>
    <div className="event-detail-container">
      <div className="event-detail-image">
        <img
          className="image-frame"
          src="https://content3.jdmagicbox.com/comp/bhagalpur/i7/9999px641.x641.191120195226.f5i7/catalogue/ram-vivah-bhawan-bhagalpur-ho-bhagalpur-banquet-halls-3nuylxb7lu.jpg"
          alt="Event"
        />
      </div>
      <div className="event-detail-content">
        <h1>🎂🎈 Birthday Party at Fun Zone 🎈🎂</h1>

        <h2>🎡 Venue: Fun Zone</h2>

        <p>
          A fun-filled place that offers thrilling rides, exciting games, and
          delicious food.
        </p>
        🎢 Rides: Roller coaster, Ferris wheel, Bumper cars, and more

        <p>
          Enjoy the adrenaline rush of the roller coaster, the panoramic view of
          the Ferris wheel, the bumpy fun of the bumper cars, and many more
          amazing rides.
        </p>
        🎮 Games: Arcade, Laser tag, Bowling, and more

        <p>
          Challenge your friends and family to the arcade games, the laser tag
          arena, the bowling alley, and many more interactive games.
        </p>
        🍕 Food: Pizza, Burgers, Ice cream, and more

        <p>
          Satisfy your hunger and cravings with the delicious pizza, burgers,
          ice cream, and many more mouth-watering treats.
        </p>
        🎁 Gifts: Souvenirs, Balloons, Stickers, and more

        <p>
          Take home some memories and souvenirs from the gift shop, such as
          balloons, stickers, keychains, and many more lovely items.
        </p>
        🎉 Celebration: Cake, Candles, Music, and more

        <p>
          Celebrate your special day with a yummy cake, colorful candles, lively
          music, and many more festive elements.
        </p>
        🌟 Your Perfect Party Awaits at Fun Zone!

        <p>
          Join us for a birthday experience that you will never forget.
        </p>
        <p>
          RSVP today and be part of this awesome party where fun, joy, and
          happiness are guaranteed. 🎁🎊
        </p>
      </div>
    </div>
    <div className="event-detail-container">
    <div className="event-detail-content">
     <h1>✨🌸 Wedding Celebration at Ram Vivah Bhawan 🌸✨</h1>

     <h2>🏰 Venue: Ram Vivah Bhawan</h2>

<p>A majestic setting that resonates with charm and elegance.</p>
🛌 Accommodation: 15 well-appointed rooms provided

<p>Ensuring comfort for the bridal party and guests, creating a home away from home.</p>
🎉 Banquet Halls: 3 spacious halls available

<p>Perfect for hosting various events and ceremonies, each exuding its unique ambiance.</p>
🚗 Parking: Ample parking space

<p>A hassle-free experience for guests with a generously sized parking area.</p>
🍽️ Storage Facility: Dedicated store room for food and packets

<p>Safely store and manage the culinary delights and thoughtful gifts.</p>
👥 Grand Hall: Capacious hall accommodating more than 100 peers

<p>A vast, beautifully adorned space, ideal for the main ceremony and celebrations.</p>
🍽️🔌 Fully Equipped: Utensils, generator, decorations, lights, and flowers provided

<p>All essential elements meticulously arranged to enhance the grandeur of the celebration.</p>
✨ Exquisite Decor: Transforming dreams into reality

<p>Immerse yourself in the enchanting ambiance created by carefully curated decorations.</p>
🎊 Celebration Ready: Every detail considered

<p>From the smallest utensil to the grandest decoration, we've got everything covered.</p>
🌟 Your Perfect Celebration Awaits at Ram Vivah Bhawan!

<p>Join us for a wedding experience that transcends imagination.</p>
<p>RSVP today and be part of this enchanting celebration where love, laughter, and memories intertwine in the magical surroundings of Ram Vivah Bhawan. 🌹💫</p>
    </div>
    <div className="event-detail-image">
      <img
        className="image-frame"
        src="https://content3.jdmagicbox.com/comp/bhagalpur/i7/9999px641.x641.191120195226.f5i7/catalogue/ram-vivah-bhawan-bhagalpur-ho-bhagalpur-banquet-halls-3nuylxb7lu.jpg"
        alt="Event"
      />
    </div>
  </div>
  </>
  );
};

export default EventDetailPage;
