import React from 'react';
import '../css/admin.css';


const ImageList = ({ images, deleteImage }) => {
  return (
    <div>
      <h2>All Images</h2>
      <div className="image-list">
        {images.map(img => (
          <div key={img._id} className="image-item">
            <img src={img.imgUrl}  className="card-img-top" alt={`Image_${img._id}`} />
            <button onClick={() => deleteImage(img._id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageList;
