// Home.js
import React from 'react';
import '../css/home.css'; // Import your CSS file for Home

const Home = () => {
  return (
    <>
    <div className="home-container">
      <div className="content">
        <div className="image">
          <img src="https://lh3.googleusercontent.com/p/AF1QipPB0Hzm0vioHlN0njferU868YsDr638sDS-qTlw=w768-h768-n-o-v1" alt="Description of the image" />
        </div>
        <div className="text">
          <h1>Welcome to Ram Vivah Bhawan</h1>
          <p>Ram Vivah Bhawan in Bhagalpur is a charming banquet hall perfect for various celebrations like birthdays, weddings, or corporate events. The hall is elegantly designed, creating a delightful ambiance for your special occasions. Situated conveniently in Bhagalpur HO, it offers more than just event space and holds a service rating of 3.6. For a lovely and well-connected venue, consider reaching out to Ram Vivah Bhawan to make your event truly memorable.</p>
        </div>
        </div>
        </div>
      <div className="home-container">
      <div className="content">
        <div className="image">
          <img src="https://files.yappe.in/place/full/maa-gauri-vivah-bhawan-4447017.webp" alt="Description of the image" />
        </div>
        <div className="text">
          <h1>Welcome to Maa Gauri Vivah Bhawan</h1>
          <p>Maa Gauri Vivah Bhawan in Bhagalpur, Bihar, is a stunning wedding venue with a stellar 4.40 out of 5-star rating. Located at Mishra Tola, Laluchak, Angari, Bhagalpur, Bihar 812001, India, it's about 2.89 kilometers from Khutha railway station. This venue provides all you need for a perfect wedding, boasting beautiful surroundings and excellent services to ensure your special day is unforgettable.</p>
        </div>
        </div>
        </div>
        </>
  );
};

export default Home;
