import React from 'react';
import '../css/admin.css';

import axios from 'axios';

const ImageUploadForm = ({ setImages }) => {
  
const handleSingleImageUpload = async (e) => {
  if (e.target.files && e.target.files.length > 0) {
    const formData = new FormData();
    formData.append('singleImage', e.target.files[0]);

    try {
      const response = await axios.post('https://wedd-7034.onrender.com/uploadsingle', formData);
      setImages(prevImages => [...prevImages, response.data.picture]);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  } else {
    console.error('No files selected.');
  }
  };
      

  return (

      <div className="card">
        <input type="file" onChange={handleSingleImageUpload} />
      </div>
  );    
};

export default ImageUploadForm;
