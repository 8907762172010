import React, { useState, useEffect } from 'react';
import ImageUploadForm from './ImageUploadForm';
import ImageList from './ImageList.js';
import axios from 'axios';
import '../css/admin.css';

const AdminPanel = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch images from the backend when the component mounts
    axios.get('https://wedd-7034.onrender.com/') // Replace with your backend URL
      .then(response => {
        setImages(response.data.images);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const deleteImage = async (id) => {
    try {
      await axios.delete(`http://localhost:4000/delete/${id}`);
      const updatedImages = images.filter(img => img._id !== id);
      setImages(updatedImages);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div>
      <h1>Admin Panel</h1>
      <ImageUploadForm setImages={setImages} />
      <ImageList images={images} deleteImage={deleteImage} />
    </div>
  );
};

export default AdminPanel;