// Import necessary modules
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/home';
import Events from './screens/events';
import Gallery from './screens/gallery';
import Venue from './screens/venue';
import Booking from './screens/booking';
import Contact from './screens/contact';
import Navbar from './components/navbar';
import Header from './components/header';
import Footer from './components/footer';
import AdminPanel from './Admin/adminpannel';


// App component
const App = () => {
  return (
    <>
    <Router>
      <Header />
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="events" element={<Events />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="venue" element={<Venue />} />
        <Route path="booking" element={<Booking />} />
        <Route path="contact" element={<Contact />} />
        <Route path="admin" element={<AdminPanel />} />
      </Routes>
    </Router>
    <Footer />
    </>

  );
};

export default App;
