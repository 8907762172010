import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import '../css/contact.css'; // Import the CSS file

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [modalIsOpen, setModalIsOpen] = useState(false); // Add a state for the modal
  const [formKey, setFormKey] = useState(0); // Add a key state

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if the message is blank
    if (!formData.message.trim()) {
      console.error('Message cannot be blank');
      // Set an error message or perform any desired action
      return;
    }
  
    try {
      await axios.post('https://wedd-7034.onrender.com/api/contact', formData);
      setModalIsOpen(true); // Open the modal
      setFormData({ name: '', email: '', message: '' });
      setFormKey((prevKey) => prevKey + 1); // Increment the key state to force re-render
    } catch (error) {
      console.error('Failed to send message', error);
    }
  };
  

  return (
    <div className="contact-container">
      <div className="contact-form">
        <h3 className="heading">Send us a message</h3>
        <form onSubmit={handleSubmit} className="formInner" key={formKey}>

          <div className="inputGroup">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" onChange={handleChange} className="input" />
          </div>

          {/* <div className="inputGroup">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" onChange={handleChange} className="input" />
          </div> */}

          <div className="inputGroup">
            <label htmlFor="message">Any Feedback:</label>
            <textarea id="message" name="message" rows="4" onChange={handleChange} className="textarea" />
          </div>

          <button type="submit" className="button">Send</button>
        </form>
        <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      color: 'lightsteelblue',
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '50%', // Adjust the width
      height: '30%', // Adjust the height
      borderRadius: '10px', // Add border radius
      padding: '20px', // Add padding
      backgroundColor: '#f7f7f7', // Change the background color
      border: '1px solid #ccc', // Add a border
    }
  }}
>
  <h2 style={{ textAlign: 'center', color: '#007bff' }}>Thank You For Your Valuable Feedback</h2> {/* Center the text and change its color */}
  <p style={{ textAlign: 'center' }}>We will always try to improve!</p> {/* Center the text */}
  <button onClick={() => setModalIsOpen(false)} style={{
    display: 'block', // Center the button
    margin: '20px auto', // Add margin
    padding: '10px 20px', // Add padding
    backgroundColor: '#007bff', // Change the background color
    color: '#fff', // Change the text color
    border: 'none', // Remove the border
    borderRadius: '4px', // Add border radius
    cursor: 'pointer', // Change the cursor
  }}>Close</button>
</Modal>

      </div>
    </div>
  );
};

export default Contact;
