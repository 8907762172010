// Footer.js
import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import '../css/footer.css'; // Import your CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-media">
          <h3>Connect with Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com">
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com">
              <FaInstagram />
            </a>
            <a href="https://www.twitter.com">
              <FaTwitter />
            </a>
          </div>
        </div>
        <div className="location">
          <h3>Our Location</h3>
          <a href="https://maps.app.goo.gl/26DwiEyA45t38x7Z9">
            <FiMapPin /> Ram Vivah Bhawan
          </a>
          <a href="https://maps.app.goo.gl/Pdg6nXZVTFQHx9GQ8">
            <FiMapPin /> Maa Gauri Vivah Bhawan
          </a>
        </div>
        <div className="site-links">
          <h3>Explore</h3>
          <ul>
            <li>

            </li>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/events">Events</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            <li>
              <a href="/venue">Venue</a>
            </li>
            <li>
              <a href="/booking">Booking</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2024 Aditya Raj Website. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
