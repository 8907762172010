import React from 'react';
import '../css/venue.css'; // Import your CSS file for styling

const App = () => {
  return (
    <div className="app-container">
      {/* Left side: Ram Vivah Bhawan */}
      <div className="venue-container">
        <h2>Ram Vivah Bhawan</h2>
        <h3>Address</h3>
        <p>Ram Vivah Bhawan is located on Laluchak-Angari Shashtri Nagar, Ishakchak, Bhagalpur, Bihar 812002</p>
        {/* Add more details as needed */}
        <div className="map-container">
          {/* Placeholder for the map */}
          <div className="map-placeholder">
            <iframe
              title="Ram Vivah Bhawan Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3608.90210548538!2d86.990784!3d25.240222!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f049f0f4fa23a9%3A0x29e951178675c273!2sRam%20Vivah%20Bhawan!5e0!3m2!1sen!2sin!4v1705780443138!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Right side: Maa Gauri Vivah Bhawan Bhagalpur */}
      <div className="venue-container">
        <h2>Maa Gauri Vivah Bhawan</h2>
        <h3>Address</h3>
       <p><b>Maa Gauri Vivah Bhawan is another rest house located on Mishra Tola, Laluchak, Angari, Bhagalpur</b></p>
        {/* Add more details as needed */}
        <div className="map-container">
          {/* Placeholder for the map */}
          <div className="map-placeholder">
            <iframe
              title="Maa Gauri Vivah Bhawan Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.0190777198445!2d86.99351449999999!3d25.2362824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f049c56269bd2f%3A0x3394d58ae0d2c07d!2sMaa%20Gauri%20Vivah%20Bhawan!5e0!3m2!1sen!2sin!4v1705780694256!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
