// Import React and useState from 'react'
import React, { useState } from 'react';
// Import axios for making HTTP requests
import axios from 'axios';
// Import React Modal
import Modal from 'react-modal';

// Import your CSS file
import '../css/bookingform.css'; // Make sure to include the correct file extension (.css)

// Define the BookingForm component
const BookingForm = () => {
  // Function to get the current date in the format YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Function to format date as "date month year"
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // State variables to manage form data and modal
  const [venue, setVenue] = useState('');
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [phoneNumber, setPhoneNumber] = useState('');
  const [purpose, setPurpose] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Validate phone number
      if (!/^\d{10}$/.test(phoneNumber)) {
        setErrorMessage('Invalid phone number. Please enter a 10-digit number.');
        return;
      }

      // Use axios to make a POST request to the server
      await axios.post('https://wedd-7034.onrender.com/api/bookings', {
        venue,
        startDate,
        endDate,
        phoneNumber,
        purpose: purpose === 'Other' ? customReason : purpose,
      });

      // Open the modal and reset form data
      setIsModalOpen(true);
      setVenue('');
      setStartDate(getCurrentDate());
      setEndDate(getCurrentDate());
      setPhoneNumber('');
      setPurpose('');
      setCustomReason('');
      setErrorMessage('');
    } catch (error) {
      // Log the error and set an error message for the user
      console.error('Error during booking:', error);
      setErrorMessage('Booking failed. Please try again.');
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Render the form with standard HTML elements and modal
  return (
    <>
      <form className="booking-form" onSubmit={handleSubmit}>
        <div className="form-control">
        <h3 className="heading">Booking Request Form</h3>
          <label>Venue</label>
          <select value={venue} onChange={(e) => setVenue(e.target.value)}>
            <option value="Ram Vivah">Ram Vivah Bhawan</option>
            <option value="Gauri Vivah">Maa Gauri Vivah Bhawan</option>
          </select>
        </div>

        <div className="form-control">
          <label>Start Date : <span>{formatDate(startDate)}</span></label>
          <div>
            
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              min={getCurrentDate()} // Set the minimum date to the current date
              className="form-input"
            />
          </div>
        </div>

        <div className="form-control">
          <label>End Date : <span>{formatDate(endDate)}</span></label>
          <div>
           
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate} // Set the minimum date to the selected start date
              className="form-input"
            />
          </div>
        </div>

        <div className="form-control">
          <label>Phone Number</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            className="form-input"
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="form-control">
          <label>Purpose</label>
          <select value={purpose} onChange={(e) => setPurpose(e.target.value)}>
            <option value="Wedding Ceremony">Wedding Ceremony</option>
            <option value="Anniversary Festivity">Anniversary Festivity</option>
            <option value="Birthday Party">Birthday Party</option>
            <option value="Meeting">Meeting</option>
            <option value="Festival Celebration">Festival Celebration</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {purpose === 'Other' && (
          <div className="form-control">
            <label>Custom Reason</label>
            <input
              type="text"
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
              placeholder="Enter your custom reason"
              className="form-input"
            />
          </div>
        )}
        
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Booking Successful"
        ariaHideApp={false} // Disable accessibility warning
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            color: 'lightsteelblue',
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: '50%', // Adjust the width
            height: '30%', // Adjust the height
            borderRadius: '10px', // Add border radius
            padding: '20px', // Add padding
            backgroundColor: '#f7f7f7', // Change the background color
            border: '1px solid #ccc', // Add a border
          }
        }}
      >
        <h2 style={{ textAlign: 'center', color: '#007bff' }}>Reservation Request Received!</h2>
        <p style={{ textAlign: 'center' }}>Your booking has been pending. We will contact you to finalize the booking.</p>

        <button onClick={closeModal} style={{
          display: 'block',
          margin: '20px auto',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}>Close</button>
      </Modal>
    </>
  );
};

// Export the BookingForm component
export default BookingForm;
